import { Route } from "react-router-dom";

export const ButtonRoute = ({destination, text}: any) => {
    return (
        <Route
            render={({ history }) => (
                <button
                    className="btn btn-corner"
                    type="button"
                    onClick={() => {
                      history.push(`${destination}`);
                    }}
                >
                  {text}
                </button>
            )}
        />
    );
};
