import axios from "axios";
import { Game } from "../interfaces/Game";
import { MatchUp } from "../interfaces/MatchUp";
import dotenv from 'dotenv'

dotenv.config()

const API = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const getMatchUp = async (matchUp: MatchUp) => {
    return await axios.post(`${API}/getMatchUp`, matchUp);
}

export const killAlert = async (matchUp: MatchUp) => {
    return await axios.post(`${API}/killAlert`, matchUp);
}

export const newGame = async (game: Game) => {
    return await axios.post(`${API}/newGame`, game);
}

export const startGame = async (game: Game) => {
    return await axios.post(`${API}/startGame`, game);
}

export const saveGame = async (game: Game) => {
    return await axios.post(`${API}/saveGame`, game);
}