import { useContext } from "react";
import { ChangeEvent } from "react";
import { GameContext } from "../../contexts/GameContext";

export const GetGame = ({handleSubmit}: any) => {
    const context = useContext(GameContext)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      context.setGame({ ...context.game, [e.target.name]: e.target.value });
    };
    return (
        <div className="enter-game">
            <img className="logo" src="/img/killer.png" alt="logo"></img>
            <h1> Manage game </h1>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <input
                        type="text"
                        maxLength={20}
                        placeholder="Game Code"
                        name="code"
                        onChange={(e) => handleChange(e)}
                        autoComplete="off"
                    />
                </div>
                <button className="btn submit-btn">MANAGE</button>
            </form>
        </div>
    );
};
