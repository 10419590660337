import { useState } from "react"
import { ItemsList } from "./ItemsList"
import { Navbar } from "./Navbar"
import * as configStates from "../../states/configGame"
import { StatesContext } from "../../contexts/StatesContext";

export const ConfigGame = () => {
    const [states, setStates] = useState(configStates.initialState())

    return (
        <>  
            <StatesContext.Provider value={{states, setStates}}>
                <Navbar states={states} />
                <ItemsList states={states}/>
            </StatesContext.Provider>
        </>
    )
}
