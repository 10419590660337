import { Home } from "./components/Home/Home";
import { NewGame } from "./components/NewGame/NewGame";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Particles from "react-tsparticles";
import type { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import { ISourceOptions } from "tsparticles-engine";
import particlesOptions from "./particles.json";
import { useCallback } from "react";

const App = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);
    
    return (
        <div className="app">
            <Particles options={particlesOptions as ISourceOptions} init={particlesInit}/>
            <div className="container" id="app-container">
                <Router>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/admin">
                        <NewGame />
                    </Route>
                </Router>
                <ToastContainer
                    position="bottom-center"
                    autoClose={2000}
                    hideProgressBar={true}
                    closeOnClick
                    draggable
                    pauseOnHover
                  />
            </div>
        </div>
    );
};

export default App;
