export const GetMatchUp = ({ setParentMatchUp, handleSubmit }: any) => {
    return (
        <>
            <img className="logo" src="/img/killer.png" alt="logo"></img>
            <h1> Enter game </h1>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <input
                        type="text"
                        maxLength={20}
                        placeholder="Game Code"
                        name="code"
                        onChange={(e) => setParentMatchUp(e)}
                        autoComplete="off"
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        maxLength={20}
                        placeholder="Your Name"
                        name="killer"
                        onChange={(e) => setParentMatchUp(e)}
                        autoComplete="off"
                    />
                </div>
                <input type="submit" value="GO" className="btn submit-btn" />
            </form>
        </>
    );
};
