import { StatesContext } from "../../contexts/StatesContext"
import { useContext } from "react"
import * as configStates from "../../states/configGame"
import { State } from "../../interfaces/State"
import { getKeyValue } from "../../services/helperService"
import { GameContext } from "../../contexts/GameContext"

export const Selector = ({state, itemContext}: any) => {
    const classes = "selector" + (state.selected ? " selected" : '')
    const statesContext = useContext(StatesContext)
    const context = useContext(GameContext)

    const handleSelect = () => {
        if (statesContext.states[state.id].selected) return
        const newState: State[] = configStates.blankState();
        newState[state.id].selected = true;
        statesContext.setStates(newState)
        itemContext.setItem({...itemContext.item, type: state.text})
    }
    return (
        <div className={classes} onClick={handleSelect}>
            <p>{state.text}: {
                getKeyValue(context.game)(state.text) && (getKeyValue(context.game)(state.text)as string[]).length
            }</p>
        </div>
    )
}
