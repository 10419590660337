import { ChangeEvent, FormEvent, useContext, useState } from "react"
import { GameContext } from "../../contexts/GameContext"
import { Selector } from "./Selector"
import { FaPlus, FaPlay, FaSignOutAlt } from 'react-icons/fa'
import Modal from 'react-modal';
import * as Helper from "../../services/helperService"
import * as api from "../../services/apiService";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        minWidth: '275px',
        background: 'lightblue',
        borderRadius: '5px',
        border: '5px solid white'
    },
};

Modal.setAppElement('#root');

export const Navbar = ({states}: any) => {
    const context = useContext(GameContext)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [item, setItem] = useState({type: "players", name: ""})
    const history = useHistory()

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setItem({ ...item, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log(item)
        if (item.type === "" || item.name === "") {
            toast.error("Please, fill the form.")
            return
        }

        const toAdd = Helper.getKeyValue(context.game)(item.type as any) as string[]

        context.setGame({...context.game, [item.type]: [item.name].concat(toAdd)});

        setItem({...item, name: ""})
        
        toast.success(`${[item.name]} has been added`)

        saveGame();
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const confirmStart = () => {
        if (context.game.players?.length !== context.game.weapons?.length || context.game.weapons?.length !== context.game.places?.length) {
            toast.error('Please, make sure you have the same amount of items for each type', {
                autoClose: 5000
            })
            return
        }

        if (context.game.players?.length === 0) {
            toast.error('Please, add items pressing the + icon', {
                autoClose: 5000
            })
            return
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="alert-confirm">
                        {!context.game.isStarted && 
                        <>
                            <h1>Start game</h1>
                            <p>Save and start game.<br /><br /> Continue?</p>
                        </>
                        }

                        {context.game.isStarted && 
                        <>
                            <h1>Restart game</h1>
                            <p>There is a game already started. If you go ahead all match ups will be restarted.<br /><br /> Continue?</p>
                        </>
                        }
                       
                        <div className="btn-container">
                            <button
                                className="btn btn-yes"
                                onClick={() => {
                                    startGame()
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                            <button className="btn btn-no" onClick={onClose}>No</button>
                        </div>             
                    </div>
                );
            }
        });
    }

    const confirmLeave = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="alert-confirm">
                        <h1>Leave game?</h1>
                        <div className="btn-container">
                            <button
                                className="btn btn-yes"
                                onClick={() => {
                                    history.push("/")
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                            <button className="btn btn-no" onClick={onClose}>No</button>
                        </div>             
                    </div>
                );
            }
        });
    }

    const startGame = async () => {

        const res = await api.startGame(context.game)
        console.log(res)
        if (res.status===200) {
            toast.success('Game has started')
        }
        history.push("/")
    }

    const saveGame = async () => {
        const res = await api.saveGame(context.game)
        console.log(res)
    }

    return (
        <div className="navbar">
            <div className="item-adder">
                <div className="show-code">GAMECODE: {context.game.code}</div>
                <div className="fa-icons">
                    <FaPlay onClick={confirmStart} />
                    <FaPlus onClick={() => setIsOpen(true)}/>
                    <FaSignOutAlt onClick={confirmLeave}/>     
                </div>
            </div>
            <div className="items-list-selector">
                {
                    states.map ((state: any) => {
                        return <Selector key={state.id} state={state} itemContext={{item, setItem}}/>
                    })
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                id="add-item-modal"
            >
                <h2>ADD ITEM</h2>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-control">
                        <select className="select-type-item" value={item.type} name="type" onChange={(e) => handleChange(e)}>
                            <option value="players">Player</option>
                            <option value="weapons">Weapon</option>
                            <option value="places">Place</option>
                        </select>
                    </div>
                    <div className="form-control">
                        <input
                            type="text"
                            maxLength={20}
                            placeholder="Item Name"
                            name="name"
                            value={item.name}
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                        />
                    </div>
                    <div className="button-add-cancel">
                        <button className="btn btn-add" type="submit"> ADD </button>
                        <button className="btn btn-cancel" onClick={closeModal}>CANCEL</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}
