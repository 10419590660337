import { FaTimes } from 'react-icons/fa'
import { useContext } from "react"
import { GameContext } from "../../contexts/GameContext"
import * as Helper from "../../services/helperService"

export const Item = ({text, idx, selected}: any) => {
    const context = useContext(GameContext)

    const deleteItem = () => {
        const toModify = Helper.getKeyValue(context.game)(selected as any) as string[]
        context.setGame({...context.game, [selected]: toModify.filter((player: string, index: number) => index !== idx )})
        console.log(context.game)
    }

    return (
        <div className="item">
            <div className="item-inner">
                <h1>{text}</h1>
                <FaTimes onClick={deleteItem}/>
            </div>
        </div>
    )
}
