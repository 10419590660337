import { State } from "../interfaces/State"

export const blankState = () => {
    return [
        {
            id: 0,
            selected: false,
            text: 'players',
        },
        {
            id: 1,
            selected: false,
            text: 'weapons',
        },
        {
            id: 2,
            selected: false,
            text: 'places',
        }
    ] as unknown as State[]
}

export const initialState = () => {
    return [
        {
            id: 0,
            selected: true,
            text: 'players'
        },
        {
            id: 1,
            selected: false,
            text: 'weapons'
        },
        {
            id: 2,
            selected: false,
            text: 'places'
        }
    ] as unknown as State[]
}
    