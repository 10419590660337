import * as api from "../../services/apiService"
import { Card } from "../Card"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useEffect } from "react";


export const ShowMatchUp = ({matchUp}: any) => {
    useEffect(() => {
        let status = "alive"
        if (matchUp.matchUp.killedBy.length === 3) {
            status = "dead"
        } else if (matchUp.matchUp.killedBy.length === 1) {
            status = "winner"
        }
        const container = document.getElementById('tsparticles')
        const canv = container?.getElementsByTagName('canvas')[0];
        if (canv) {
            switch(status) {
                case 'dead':
                    canv.style.backgroundColor = '#a00000'
                    return;
                case 'winner':
                    canv.style.backgroundColor = '#19c419'
                    return;
                default:
                  return;
              }            
        }
    })

    const confirmKill = () => confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="alert-confirm">
                    <h1>Report death</h1>
                    <p>By reporting your death, your killer will take your assignments.<br /><br /> Continue?</p>
                    <div className="btn-container">
                        <button
                            className="btn btn-yes"
                            onClick={() => {
                                killAlertApi()
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                        <button className="btn btn-no" onClick={onClose}>No</button>
                    </div>             
                </div>
            );
        }
      });

    const killAlertApi = async () => {
        const res = await api.killAlert(matchUp.matchUp)
        console.log(res)

        matchUp.setMatchUp({...res.data, code: matchUp.matchUp.code});
    }
    return (
        <>  
            <h3>Hi, {matchUp.matchUp.killer}</h3>
            {matchUp.matchUp.killedBy.length === 0 &&
            <>
                <Card logo="/img/victim_white.png" text={matchUp.matchUp.victim}/>
                <Card logo="/img/weapon_white.png" text={matchUp.matchUp.weapon}/>
                <Card logo="/img/place_white.png" text={matchUp.matchUp.place}/>
            </>
            }
            { matchUp.matchUp.killedBy.length === 3 &&
            <h1> GAME OVER </h1>
            }
            { matchUp.matchUp.killedBy.length === 1 &&
            <h1> YOU WON!!! </h1>
            }
            {(matchUp.matchUp.killedBy.length > 0 || matchUp.matchUp.pastVictims.length > 0) &&
                <div className="kills-sheet">
                    {
                        matchUp.matchUp.pastVictims.map((pastVictim: string, idx: number) => {
                            return (
                                <p>You killed {pastVictim} with {matchUp.matchUp.pastWeapons[idx]} in {matchUp.matchUp.pastPlaces[idx]}</p>
                            )
                        })
                    }
                    {
                        (matchUp.matchUp.killedBy.length === 3 && matchUp.matchUp.pastVictims.length > 0) &&
                        <h2>BUT</h2>
                    }
                    {
                        matchUp.matchUp.killedBy.length === 3 &&
                        <p> You've been killed by {matchUp.matchUp.killedBy[0]} with {matchUp.matchUp.killedBy[1]} in {matchUp.matchUp.killedBy[2]}</p>
                    }
                </div>
            }
            {matchUp.matchUp.killedBy.length < 1 &&
                <button onClick={confirmKill} className="btn kill-btn">I have been killed</button>
            }
        </>
    )
}
