import { GameContext } from "../../contexts/GameContext"
import { Item } from "./Item"
import { useContext } from "react"
import * as Helper from "../../services/helperService"

export const ItemsList = ({states}: any) => {
    const context = useContext(GameContext)

    const selected = states.find ((state: any) => state.selected === true )
    const toShow = Helper.getKeyValue(context.game)(selected.text) as string[]

    return (
        <div className="items-list">
            {toShow.map((item: string, idx: number) => {
                return (
                    <Item key={idx} text={item} idx={idx} selected={selected.text}/>
                )
            })}
        </div>
    )
}
