import { ChangeEvent, FormEvent, useState } from "react"
import { GetMatchUp } from "./GetMatchUp"
import { ShowMatchUp } from "./ShowMatchUp"
import { ButtonRoute } from "../ButtonRoute"
import * as api from '../../services/apiService'
import { MatchUp } from "../../interfaces/MatchUp"
import { toast } from "react-toastify"
import { FaSync } from "react-icons/fa"

export const Home = () => {
    const [showMatchup, setShowMatchUp] = useState(false)
    const [matchUp, setMatchUp] = useState<MatchUp>({code: "", killer: ""})

    const setParentMatchUp = (e: ChangeEvent<HTMLInputElement>) => {
        setMatchUp({ ...matchUp, [e.target.name]: e.target.value });
    }
    
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (matchUp.code === "" || matchUp.killer === "") {
            toast.error("Please, fill the form.") 
            return
        }
        getData()
    }

    const handleUpdate = () => {
        getData()
        toast.success("Updated!") 
    } 

    const goHome = () => {
        setMatchUp({code: "", killer: ""})
        setShowMatchUp(false)
        const container = document.getElementById('tsparticles')
        const canv = container?.getElementsByTagName('canvas')[0]
        canv && (canv.style.backgroundColor = '#282c34')
    }

    const getData = async () => {
        const res = await api.getMatchUp(matchUp);
        if (res.data === "") {
            toast.error("Data not found.") 
            return
        }
        setMatchUp({...res.data, code: matchUp.code});
        setShowMatchUp(true)
        console.log(res)
    }

    return (
        <>
            {!showMatchup && <ButtonRoute  destination="/admin" text="ADMIN"/>}
            {showMatchup && 
            <>
                <FaSync id="sync" onClick={handleUpdate}/>
                <button className="btn btn-corner" onClick={goHome}>HOME</button>
            </>
            }
            <div className="enter-game">
                {!showMatchup && <GetMatchUp setParentMatchUp={setParentMatchUp} handleSubmit={handleSubmit} />}
                {showMatchup && <ShowMatchUp matchUp={{matchUp, setMatchUp}}/>}
            </div>
        </>
    )
}
