export const Card = ({logo, text}: any) => {
    return (
        <div className="card">
            <div className="card-inner">
                <img alt="" src={logo}></img>
                <h1>{text}</h1>
            </div>
        </div>
    )
}
