import { Game } from "../../interfaces/Game";
import { FormEvent, useState } from "react";
import * as api from "../../services/apiService";
import { GetGame } from "./GetGame";
import { ButtonRoute } from "../ButtonRoute";
import { ConfigGame } from "./ConfigGame";
import { GameContext } from "../../contexts/GameContext";
import { toast } from 'react-toastify';

export const NewGame = () => {
    const [showConfig, setshowConfig] = useState(false);
    const [game, setGame] = useState<Game>({ code: "", isStarted: false });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (game.code === "") {
            toast.error("Please, fill the form.")
            return;
        }
        const res = await api.newGame(game);

        setGame(res.data);
        setshowConfig(true)
    };

    return (
        <>
            {!showConfig && <ButtonRoute destination="/" text="HOME" />}
            <GameContext.Provider value={{game, setGame}}>
                {!showConfig && (
                    <GetGame
                        handleSubmit={handleSubmit}
                    />
                )}
                {showConfig && (
                    <ConfigGame />
                )}
            </GameContext.Provider>
        </>
    );
};
